import React, { useState, useCallback, useEffect, useContext } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { ViewMode } from "@nebula.gl/edit-modes";

import { MainPageContainer } from "./styled.js";
import { NoiseContext } from "../../context/NoisesContext.js";
import { TracksContext } from "../../context/TracksContext.js";
import { getTrackComparation, updateTrack } from "../../services/requests.js";
import MapLeftSidebar from "../../components/MapLeftSidebar.js";
import TracksWidget from "../../features/TracksWidget/TracksWidget.js";
import Map from "../../features/Map/Map.js";

function MainPage() {
  const [communityStatistics, setCommunityStatistics] = useState({
    positive: "?",
    negative: "?",
  });

  const { comparingTrackId, selectedTrack, selectedTrackId } =
    useContext(TracksContext);
  const [lastSelectedTrackId, setLastSelectedTrackId] = useState();
  const { updateTrackNoiseContours, noiseContours } = useContext(NoiseContext);
  const [mode, setMode] = useState(() => ViewMode);

  const [residents, setResidents] = useState([]);

  // Create states for delayed API calls
  const [processingTrack, setProcessingTrack] = useState(false);

  const setResidentsData = useCallback((data) => {
    setResidents(data);

    // Make sure that there is minimum noise level of 70BA
    data = data.filter((x) => Math.max(x.baseline, x.alternative) >= 70);

    const noiseImprovementThreshold = 5;
    // Get the positive and negative changes (positive when d < 0, negative when d > 0), where d is the alternative minus baseline
    let negativeChanges = data.filter(
      (x) => x.difference > noiseImprovementThreshold
    );
    let positiveChanges = data.filter(
      (x) => x.difference < -noiseImprovementThreshold
    );

    // Update the statistics
    setCommunityStatistics({
      // Count the total number of residents with the changes
      negative: negativeChanges.reduce((p, a) => p + a.residents, 0),
      positive: positiveChanges.reduce((p, a) => p + a.residents, 0),
    });
  }, []);

  const getTracksComparation = useCallback(
    async (comparingId = comparingTrackId) => {
      const trackId = selectedTrack?.properties?.track_id;
      const validSelectedTrack = !isNaN(trackId);

      const validComparingTrack = comparingId !== null && !isNaN(comparingId);

      if (validSelectedTrack && validComparingTrack) {
        const residentsData = await getTrackComparation(trackId, comparingId);
        setResidentsData(residentsData);
      }
    },
    [selectedTrack, comparingTrackId, setResidentsData]
  );

  const processTrack = useCallback(async () => {
    setProcessingTrack(true);

    const trackId = selectedTrack?.properties?.track_id;

    if (!isNaN(trackId)) {
      await updateTrack(selectedTrack);
      await updateTrackNoiseContours(trackId);
      await getTracksComparation();
    }
    setProcessingTrack(false);
  }, [selectedTrack, getTracksComparation, updateTrackNoiseContours]);

  useEffect(() => {
    if (lastSelectedTrackId !== selectedTrackId) {
      const validNoise =
        Object.keys(noiseContours).includes(String(selectedTrackId)) &&
        noiseContours[selectedTrackId]?.valid;

      if (!validNoise) {
        processTrack();
      }
      setLastSelectedTrackId(selectedTrackId);
    }
  }, [
    noiseContours,
    processTrack,
    selectedTrack,
    selectedTrackId,
    setLastSelectedTrackId,
    lastSelectedTrackId,
  ]);

  return (
    <MainPageContainer>
      <MapLeftSidebar processTrack={processTrack} />
      <Map
        mode={mode}
        processingTrack={processingTrack}
        residents={residents}
        processTrack={processTrack}
      />
      <TracksWidget
        communityStatistics={communityStatistics}
        comparing={processingTrack}
        getTracksComparation={getTracksComparation}
        setMode={setMode}
      />
    </MainPageContainer>
  );
}

export default MainPage;
