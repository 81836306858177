import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./routes/ErrorPage/ErrorPage.js";
import Login from "./features/Login/Login.js";
import { AuthorizationHandler } from "./routes/AuthorizationHandler.js/AuthorizationHandler.js";
import { Auth0Provider } from "@auth0/auth0-react";
import MainPage from "./routes/MainPage/MainPage.js";

const domainValue = process.env.REACT_APP_AUTH0_DOMAIN;
const clientIdValue = process.env.REACT_APP_AUTH0_CLIENT_ID;

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthorizationHandler />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <MainPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

export default function App() {
  return (
    <Auth0Provider
      domain={domainValue}
      clientId={clientIdValue}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <RouterProvider router={router} />
    </Auth0Provider>
  );
}
