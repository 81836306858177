export const theme = {
  //for example: margins and paddings
  spaces: {
    tiny: '0.2rem',
    small: '0.5rem',
    normal: '1rem',
    large: '2rem',
    huge: '4rem',
  },
  //for example: heights and widths
  sizes: {
    small: '1rem',
    normal: '2rem',
    large: '4rem',
    huge: '8rem',
  },
  borderRadius: {
    tiny: '2px',
    small: '5px',
    normal: '10px',
  },
  fonts: {
    weight: {
      thin: '200',
      light: '300',
      normal: '400',
      medium: '600',
      bold: '700',
      bolder: '800',
    },
    size: {
      tiny: '0.6rem',
      small: '0.8rem',
      medium: '1rem',
      mediumLarge: '1.2rem',
      large: '1.4rem',
      huge: '1.6rem',
    },
    lineHeight: {
      half: 0.5,
      single: 1,
      normal: 1.125,
      normalToWide: 1.25,
      wide: 1.5,
      double: 2,
    },
  },
  colors: {
    //primary colors
    white: '#fff',
    black: '#000',
    lighterGray: '#eee',
    lightGray: '#3f3f3f43',

    //brand colors
    brandBlue: '#1d3d91',
    brandYellow: '#f4e408',
    brandRed: '#bd0e0b',
    brandGreen: '#25ef46',

    //alert
    alertYellow: '#eacb65cc',

    //map colors
    noiseColor: '#f0e430',
    runwayColor: '#ff0000',
    departureTrackColor: '#7ad2f5',
  },
  breakpoints: {
    small: '420px',
    medium: '720px',
  },
};
