import React, { useContext } from "react";
import { ContentLoader } from "../../styles/ContentLoader";
import { InlineSmallGap } from "../../styles/Structure";
import { TracksContext } from "../../context/TracksContext";

const Statistics = ({ communityStatistics, processingTrack }) => {
  const { comparingTrackId } = useContext(TracksContext);

  if (!comparingTrackId) {
    return null;
  }

  return (
    <strong>
      <InlineSmallGap>
        {processingTrack ? (
          <ContentLoader $width={"40px"} $height={"20px"} />
        ) : (
          communityStatistics.positive
        )}{" "}
        improved,{" "}
        {processingTrack ? (
          <ContentLoader $width={"40px"} $height={"20px"} />
        ) : (
          communityStatistics.negative
        )}{" "}
        worsened
      </InlineSmallGap>
    </strong>
  );
};

export default Statistics;
