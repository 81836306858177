import React, { useContext } from "react";
import DeckGL from "@deck.gl/react";
import { StaticMap } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { magicTooltip } from "../../features/Map/mapHelpers.js";

import runwaysLayer from "../Layers/runwaysLayer.js";
import {
  useWaypointTextlayer,
  waypointLayer,
} from "../Layers/waypointsLayer.js";
import useTracksLayer from "../Layers/tracksLayer.js";
import useNoiseLayer from "../Layers/noiseLayer.js";
import useEditableTrackLayer from "../Layers/editableTrackLayer/editableTrackLayer.js";
import useResidentsLayer from "../Layers/residentsLayer.js";
import useResidentialAreaLayer from "../Layers/residentialAreasLayer.js";
import { MapStateContext } from "../../context/MapStateContext.js";
import { TracksContext } from "../../context/TracksContext.js";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAP_TOKEN;

const Map = ({ mode, processingTrack, residents, processTrack }) => {
  const { viewState, setViewState } = useContext(MapStateContext);
  const { tracks } = useContext(TracksContext);

  const editableTrackLayer = useEditableTrackLayer({
    mode,
    processTrack,
    processingTrack,
  });

  const { comparingNoiseLayer, selectedNoiseLayer } = useNoiseLayer({
    processingTrack,
  });

  const residentsLayer = useResidentsLayer({
    residents,
    processingTrack,
  });

  const residentialAreasLayer = useResidentialAreaLayer({
    processingTrack,
  });

  const waypointTextlayer = useWaypointTextlayer(viewState.zoom > 10);

  const tracksLayer = useTracksLayer();

  // first layers are below in the map
  const myLayers = [
    runwaysLayer,
    residentialAreasLayer,
    comparingNoiseLayer,
    selectedNoiseLayer,
    waypointLayer,
    waypointTextlayer,
    tracksLayer,
    editableTrackLayer,
    residentsLayer,
  ];

  return (
    <DeckGL
      viewState={viewState}
      controller={{
        doubleClickZoom: true,
      }}
      layers={myLayers}
      getTooltip={({ object }) => object && magicTooltip(object, tracks)}
      getCursor={editableTrackLayer.getCursor.bind(editableTrackLayer)}
      onViewStateChange={(e) => {
        setViewState(e.viewState);
      }}
    >
      <StaticMap
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle="mapbox://styles/mapbox/dark-v9"
      />
    </DeckGL>
  );
};

export default Map;
