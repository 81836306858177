import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "styled-components";
import { AppGlobalStyles } from "./styling/global";
import { theme } from "./styling/theme";
import { MapStateContextProvider } from "./context/MapStateContext.js";
import { TracksContextProvider } from "./context/TracksContext.js";
import { NoiseContextProvider } from "./context/NoisesContext.js";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <TracksContextProvider>
        <NoiseContextProvider>
          <MapStateContextProvider>
            <AppGlobalStyles />
            <App />
          </MapStateContextProvider>
        </NoiseContextProvider>
      </TracksContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
