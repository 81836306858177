import styled from "styled-components";

const ColumnSmallGap = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const InlineSmallGap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const InlineGap = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const InlineSmallGapCentered = styled(InlineSmallGap)`
  align-items: center;
`;

export { ColumnSmallGap, InlineSmallGap, InlineGap, InlineSmallGapCentered };
