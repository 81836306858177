import styled from "styled-components";

const CheckboxLabel = styled.label`
  margin: 0;
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

export { CheckboxLabel };
