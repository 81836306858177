import degrees from "../tools/degrees";

const waypoints = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        name: "DTHR 18L",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(4, 46, 47), degrees(52, 18, 58)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "EH009",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(4, 33, 16), degrees(52, 13, 25)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "EH024",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(4, 58, 50), degrees(52, 15, 11)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "EH028",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(4, 25, 16), degrees(52, 21, 28)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "EH029",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(4, 49, 14), degrees(52, 11, 16)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "EH037",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(4, 46, 28), degrees(52, 15, 28)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "EH050",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(4, 55, 5), degrees(52, 9, 19)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "EH050",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(4, 48, 28), degrees(52, 12, 51)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "LEKKO",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(4, 46, 2.39), degrees(51, 55, 27.03)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "LOPIK",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(5, 7, 44.96), degrees(51, 55, 50.98)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "VALKO",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(3, 50, 23.39), degrees(51, 55, 50.98)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "IVLUT",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(5, 15, 25.06), degrees(52, 14, 38.81)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "PAMPUS",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(5, 5, 32), degrees(52, 20, 5)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "ANDIK",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(5, 16, 13.76), degrees(52, 44, 21.85)],
      },
    },
    {
      type: "Feature",
      properties: {
        name: "BERGI",
      },
      geometry: {
        type: "Point",
        coordinates: [degrees(4, 21, 32.15), degrees(52, 44, 55.5)],
      },
    },
  ],
};

export default waypoints;
