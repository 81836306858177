const magicTooltip = (object, tracks) => {
  // Residents
  if (object.hasOwnProperty("neighbourhood")) {
    return `Neighbourhood ${object.neighbourhood}\n
      ${object.residents} residents\n
      ${object.difference.toFixed(1)} dBA ΔSEL`;
  }

  // Contours
  else if (object.properties.hasOwnProperty("level")) {
    return `Contour for ${
      tracks.features[object.properties.track_id].properties.name
    }\n
      ${object.properties.level} dB SEL`;
  }
};

export { magicTooltip };
