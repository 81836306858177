import { createContext, useState } from "react";

export const MapStateContext = createContext(undefined);

export const MapStateContextProvider = ({ children }) => {
  const [viewState, setViewState] = useState({
    longitude: 4.75,
    latitude: 52.3,
    zoom: 11,
  });

  const [showNoise, setShowNoise] = useState(true);
  const [showResidentialAreas, setShowResidentialAreas] = useState(true);

  const toogleResidentialAreas = () => {
    setShowResidentialAreas((prev) => !prev);
  };
  const toogleNoise = () => {
    setShowNoise((prev) => !prev);
  };

  const contextObject = {
    viewState,
    setViewState,
    showNoise,
    showResidentialAreas,
    toogleNoise,
    toogleResidentialAreas,
  };

  return (
    <MapStateContext.Provider value={contextObject}>
      {children}
    </MapStateContext.Provider>
  );
};
