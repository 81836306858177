import styled from "styled-components";
import { ColumnSmallGap, InlineGap, InlineSmallGap } from "./Structure";

const Widget = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  border: 2px solid lightgray;

  & > * {
    padding: 15px;
  }
`;

const WidgetTitle = styled(InlineGap)`
  font-weight: 700;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid lightgray;
`;

const WidgetContent = styled(ColumnSmallGap)``;
const WidgetFooter = styled(InlineSmallGap)`
  border-top: 2px solid lightgray;
  justify-content: end;
`;

const WidgetHolder = styled(ColumnSmallGap)`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 10px;
  max-width: 300px;
`;

export { Widget, WidgetTitle, WidgetContent, WidgetFooter, WidgetHolder };
