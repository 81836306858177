import React, { useContext } from "react";
import { SidebarTitle, SidebarElement } from "./styled";
import TopIcons from "./TopIcons";
import { TracksContext } from "../../../context/TracksContext";

function TracksSidebar({ setSelectedFeatureIndex, setEditTrackPopUp }) {
  const { tracks, setSelectedTrackId, selectedTrackId, setComparingTrackId } =
    useContext(TracksContext);

  const handleTrackClick = (trackId) => {
    setSelectedTrackId(trackId);
    setComparingTrackId(null);
  };

  return (
    <div>
      <SidebarTitle>
        Tracks
        <TopIcons
          setEditTrackPopUp={setEditTrackPopUp}
          setSelectedFeatureIndex={setSelectedFeatureIndex}
        />
      </SidebarTitle>
      {tracks.features.map((track) => (
        <SidebarElement
          key={track.properties.track_id}
          onClick={() => handleTrackClick(track.properties.track_id)}
          $selected={selectedTrackId === track.properties.track_id}
        >
          <div>{track.properties.name}</div>
          <div>Departure {track.properties.runway}</div>
        </SidebarElement>
      ))}
    </div>
  );
}

export default TracksSidebar;
