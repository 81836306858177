import styled from "styled-components";
import { WidgetTitle } from "../../styles/Widgets";
import { TransparentInput } from "../../styles/Forms";

const ListHolder = styled.div`
  max-height: 120px;
  overflow-y: auto;
  border: 1px solid lightgray;
`;

const UnorderedList = styled.ul`
  margin: 5px 5px 5px 30px;
  padding: 0;
`;
const ListItem = styled.li``;

const EditTrackTitle = styled(WidgetTitle)`
  align-items: start;
`;

const TrackNameInput = styled(TransparentInput)`
  font-weight: 700;
  max-width: 140px;
`;

export { TrackNameInput, EditTrackTitle, ListHolder, UnorderedList, ListItem };
