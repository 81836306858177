function runwayScorer(a) {
  var re = /\d+/;
  var direction = a.match(re);
  var score = Number(direction[0]) * 10;
  if (a.match(/L/)) {
    score += 1;
  } else if (a.match(/C/)) {
    score += 2;
  } else if (a.match(/R/)) {
    score += 3;
  }
  return score;
}
export default runwayScorer;
