import React, { useState } from "react";
import "../styles/MapLeftSidebar.css";
import companyLogo from "../images/company-white-logo.png";
import routeEditor from "../images/left-side-bar-logos/line-with-pencil.svg";
import TracksSidebar from "../features/Sidebar/TracksSidebar/TracksSidebar";

function MapLeftSidebar({ processTrack }) {
  const [openRouteEditor, setOpenRouteEditor] = useState(false);

  return (
    <aside className="left-sidebar-container">
      <section className="sidebar-logo-section">
        <a
          className="topbar-logo"
          href="https://aerlabs.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={companyLogo}
            alt="AerLabs company logo."
            id="company-logo"
          />
        </a>
      </section>
      <section className="left-sidebar-icon-section">
        <div className="sidebar-icon-container">
          <img
            src={routeEditor}
            alt="editing logo."
            className="sidebar-icons"
            onClick={() => {
              setOpenRouteEditor(!openRouteEditor);
            }}
          />
        </div>
      </section>
      <section className="second-sidebar-components">
        {openRouteEditor && (
          <div className="second-sidebar-component">
            <TracksSidebar processTrack={processTrack} />
          </div>
        )}
      </section>
    </aside>
  );
}

export default MapLeftSidebar;
