import { createGlobalStyle } from 'styled-components';

export const AppGlobalStyles = createGlobalStyle`
       body {
            margin: 0;
            font-family: 'Nunito';
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            width: 100vw;
            height: 100vh;
        }

        #root {
            width: 100vw;
            height: 100vh;
        }
`;
