import styled from "styled-components";

const TransparentInput = styled.input`
  border: 1px solid white;
  outline: none;
  border-radius: 2px;

  &:focus {
    border: 1px solid lightgray;
  }
`;

export { TransparentInput };
