import React, { useCallback, useContext, useState } from "react";
import { ImportModal } from "@nebula.gl/editor";
import uploadFile from "../../../images/track-left-side-logos/file_upload.svg";
import plusIcon from "../../../images/track-left-side-logos/add-icon-without-box.svg";
import { IconImg } from "../../../styles/Icons";
import { InlineSmallGap } from "../../../styles/Structure";
import runways from "../../../static-data/runways.json";
import { TracksContext } from "../../../context/TracksContext";

const TopIcons = ({ setEditTrackPopUp, setSelectedFeatureIndex }) => {
  const { tracks, setTracks } = useContext(TracksContext);

  const createTrack = useCallback(() => {
    const feature = runways.features[0];
    let currentFeature = JSON.parse(JSON.stringify(feature));
    currentFeature["properties"]["name"] =
      "Departure from " + feature.properties.runway;

    setTracks({
      ...tracks,
      features: [...tracks.features, currentFeature],
    });
    setSelectedFeatureIndex(tracks.features.length);
  }, [setTracks, tracks, setSelectedFeatureIndex]);

  const [showImport, setShowImport] = useState(false);

  return (
    <InlineSmallGap>
      <IconImg
        src={uploadFile}
        alt="an icon witholding upload a file functionality."
        className="topbar-icon"
        onClick={() => {
          setShowImport(true);
        }}
      />
      <IconImg
        src={plusIcon}
        alt="a plus icon which can be used for creating a new track."
        className="topbar-icon"
        onClick={() => {
          setEditTrackPopUp(true);
          createTrack();
        }}
      />
      {showImport && (
        <ImportModal
          onImport={(geojson) => setTracks(geojson)}
          onClose={() => setShowImport(false)}
        />
      )}
    </InlineSmallGap>
  );
};
export default TopIcons;
