import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingPage from "../../features/LoadingPage/LoadingPage";

export const AuthorizationHandler = ({ redirectPath = "/login" }) => {
  const { isLoading, isAuthenticated } = useAuth0();

  // Show loading screen while authentication is loading
  if (isLoading) {
    return <LoadingPage />;
  }

  // If user is not authenticated, redirect to pre-defined path. It should be a public page
  if (!isLoading && !isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
