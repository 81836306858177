import React, { useState } from "react";
import {
  ViewMode,
  ModifyMode,
  ExtendLineStringMode,
} from "@nebula.gl/edit-modes";
import { useTheme } from "styled-components";
import Tooltip from "react-simple-tooltip";
import LockSvg from "../../images/edit-track/LockSvg";
import EditSvg from "../../images/edit-track/EditSvg";
import AddSvg from "../../images/edit-track/AddSvg";
import { InlineSmallGap } from "../../styles/Structure";

const OPTIONS = {
  VIEW: {
    tooltipContent: "view mode",
    modeToSet: ViewMode,
    Icon: LockSvg,
  },
  EXTEND: {
    tooltipContent: "extend mode",
    modeToSet: ExtendLineStringMode,
    Icon: AddSvg,
  },
  MODIFY: {
    tooltipContent: "edit mode",
    modeToSet: ModifyMode,
    Icon: EditSvg,
  },
};

function ViewEdit({ setMode }) {
  const [selectedOption, setSelectedOption] = useState("VIEW");
  const theme = useTheme();

  const handleIconClick = (mode, key) => {
    setMode(() => mode);
    setSelectedOption(key);
  };

  return (
    <InlineSmallGap>
      {Object.entries(OPTIONS).map(([key, option]) => {
        const { Icon, tooltipContent, modeToSet } = option;
        const isSelected = key === selectedOption;
        return (
          <Tooltip
            key={key}
            content={tooltipContent}
            onClick={() => handleIconClick(modeToSet, key)}
          >
            <Icon
              backgroundColor={
                isSelected ? theme.colors.brandBlue : "lightgray"
              }
              color={isSelected ? "white" : "black"}
            />
          </Tooltip>
        );
      })}
    </InlineSmallGap>
  );
}

export default ViewEdit;
