import { useContext } from "react";
import { TracksContext } from "../../context/TracksContext";
import EditTrack from "../EditTrack/EditTrack";
import { WidgetHolder } from "../../styles/Widgets";
import ComparationWidget from "../ComparationWidget/ComparationWidget";

const TracksWidget = ({
  communityStatistics,
  setMode,
  processingTrack,
  getTracksComparation,
}) => {
  const { selectedTrack } = useContext(TracksContext);

  if (selectedTrack)
    return (
      <WidgetHolder>
        <EditTrack setMode={setMode} />
        <ComparationWidget
          communityStatistics={communityStatistics}
          processingTrack={processingTrack}
          getTracksComparation={getTracksComparation}
        />
      </WidgetHolder>
    );
  return null;
};

export default TracksWidget;
