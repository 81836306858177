import styled, { keyframes, css } from 'styled-components';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const ContentLoader = styled.div`
  ${({ $height, $width }) => {
    return css`
      height: ${$height};
      width: ${$width};
      border-radius: 2px;
      background-color: lightgrey;
      background: linear-gradient(-45deg, #eee, #bbb);
      background-size: 400% 400%;
      animation: ${gradientAnimation} 2s ease infinite;
    `;
  }}
`;
