import { GeoJsonLayer } from "@deck.gl/layers";
import { useMemo, useContext } from "react";

import { TracksContext } from "../../context/TracksContext.js";

const useTracksLayer = () => {
  const { tracks, selectedTrackId } = useContext(TracksContext);

  const filteredTracks = useMemo(
    () => ({
      ...tracks,
      features: tracks.features.filter(
        (feature) => feature.properties.track_id !== selectedTrackId
      ),
    }),
    [selectedTrackId, tracks]
  );

  const tracksLayer = useMemo(
    () =>
      new GeoJsonLayer({
        id: "tracks-layer",
        data: filteredTracks,
        lineWidthMinPixels: 2,
        getLineWidth: 20,
        getLineColor: [255, 255, 255, 255],
      }),
    [filteredTracks]
  );
  return tracksLayer;
};

export default useTracksLayer;
