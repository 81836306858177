import { useContext, useMemo } from "react";
import residentialAreas from "../../static-data/residential_areas.json";
import { GeoJsonLayer } from "@deck.gl/layers";
import { MapStateContext } from "../../context/MapStateContext";

const useResidentialAreaLayer = ({ processingTrack }) => {
  const { showResidentialAreas } = useContext(MapStateContext);
  const residentialAreasLayer = useMemo(
    () =>
      new GeoJsonLayer({
        id: "residentialsAreasLayer",
        data: residentialAreas,
        getFillColor: [244, 227, 8],
        opacity: processingTrack ? 0.025 : 0.05,
        stroked: false,
        filled: true,
        wireframe: true,
        fp64: true,
        visible: showResidentialAreas,
        transitions: {
          opacity: 400,
        },
      }),
    [processingTrack, showResidentialAreas]
  );
  return residentialAreasLayer;
};
export default useResidentialAreaLayer;
