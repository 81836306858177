import GridLoader from "react-spinners/GridLoader";
import { LoaderHolder } from "./styled";

const LoadingPage = () => {
  return (
    <LoaderHolder>
      <GridLoader size={25} />
    </LoaderHolder>
  );
};

export default LoadingPage;
