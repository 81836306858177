import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { getTrackNoise } from "../services/requests";
import { TracksContext } from "./TracksContext";

export const NoiseContext = createContext(undefined);

export const NoiseContextProvider = ({ children }) => {
  const [noiseContours, setNoiseContours] = useState({});
  const { comparingTrackId, selectedTrackId } = useContext(TracksContext);

  const updateTrackNoiseContours = useCallback(
    async (trackId) => {
      const noise = await getTrackNoise(trackId);

      const newNoiseContours = { ...noiseContours };
      newNoiseContours[trackId] = noise;
      newNoiseContours[trackId].valid = true;
      setNoiseContours(newNoiseContours);
    },
    [noiseContours]
  );

  const invalidateNoiseContours = useCallback(
    (trackId) => {
      const newNoiseContours = { ...noiseContours };
      newNoiseContours[trackId].valid = false;
      setNoiseContours(newNoiseContours);
    },
    [noiseContours]
  );

  const getNoiseContoursByTrackId = useCallback(
    (trackId) => ({
      type: "FeatureCollection",
      features: Object.entries(noiseContours)
        .filter(([key]) => String(key) === String(trackId))
        .flatMap(([_, noise]) => noise),
    }),
    [noiseContours]
  );

  const contextObject = useMemo(
    () => ({
      noiseContours,
      noiseKeys: Object.keys(noiseContours),
      selectedNoiseContours: getNoiseContoursByTrackId(selectedTrackId),
      comparingNoiseContours: getNoiseContoursByTrackId(comparingTrackId),
      updateTrackNoiseContours,
      invalidateNoiseContours,
    }),
    [
      selectedTrackId,
      comparingTrackId,
      noiseContours,
      updateTrackNoiseContours,
      getNoiseContoursByTrackId,
      invalidateNoiseContours,
    ]
  );

  return (
    <NoiseContext.Provider value={contextObject}>
      {children}
    </NoiseContext.Provider>
  );
};
