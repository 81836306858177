import { createContext, useMemo, useState } from "react";
import tracksData from "../static-data/tracks.json";
import useLocalStorage from "react-use-localstorage";

export const TracksContext = createContext(undefined);

export const TracksContextProvider = ({ children }) => {
  const [tracksLocalStorage, setTracksLocalStorage] = useLocalStorage(
    "tracks",
    JSON.stringify(tracksData)
  );

  const [tracks, setTracks] = useState(JSON.parse(tracksLocalStorage));

  const [selectedTrackId, setSelectedTrackId] = useState();
  const [comparingTrackId, setComparingTrackId] = useState("");

  const selectedTrack = useMemo(() => {
    return tracks.features?.find(
      (track) => track.properties.track_id === selectedTrackId
    );
  }, [tracks, selectedTrackId]);

  const setTracksEnhanced = (newTracks) => {
    setTracks(newTracks);
    setTracksLocalStorage(JSON.stringify(newTracks));
  };

  const updateTrack = (trackId, updateData) => {
    const newFeatures = tracks.features.map((feature) => {
      if (feature.properties.track_id === trackId) {
        return updateData.features[0];
      }
      return feature;
    });
    setTracksEnhanced({ ...tracks, features: newFeatures });
  };

  const contextObject = {
    tracks,
    setTracks: setTracksEnhanced,
    updateTrack,
    selectedTrack,
    selectedTrackId,
    setSelectedTrackId,
    comparingTrackId,
    setComparingTrackId,
  };

  return (
    <TracksContext.Provider value={contextObject}>
      {children}
    </TracksContext.Provider>
  );
};
