import { GeoJsonLayer } from "@deck.gl/layers";
import { useRef, useState, useMemo, useEffect, useContext } from "react";
import { MapStateContext } from "../../context/MapStateContext";
import { NoiseContext } from "../../context/NoisesContext";

const NORMAL_OPACITY = 1;
const OPACITY_LOADING_1 = 0.1;
const OPACITY_LOADING_2 = 1;
const TRANSITION_TIME_MS = 600;

const useNoiseLayer = ({ processingTrack }) => {
  const intervalRef = useRef();
  const [opacity, setOpacity] = useState(1);

  const { selectedNoiseContours, comparingNoiseContours } =
    useContext(NoiseContext);
  const { showNoise } = useContext(MapStateContext);

  useEffect(() => {
    if (processingTrack) {
      setOpacity(OPACITY_LOADING_1);
      intervalRef.current = setInterval(() => {
        setOpacity((prev) => {
          if (prev !== OPACITY_LOADING_1) return OPACITY_LOADING_1;
          return OPACITY_LOADING_2;
        });
      }, TRANSITION_TIME_MS);
    } else {
      clearTimeout(intervalRef.current);
      setOpacity(NORMAL_OPACITY);
    }
  }, [processingTrack, setOpacity]);

  const comparingNoiseLayer = useMemo(
    () =>
      new GeoJsonLayer({
        id: "comparingNoiseLayer",
        data: comparingNoiseContours,
        pickable: true,
        filled: true,
        stroked: false,
        lineWidthUnits: "pixels",
        opacity: 1,
        visible: showNoise,
        getFillColor: [255, 255, 255, 64],
      }),
    [comparingNoiseContours, showNoise]
  );

  const selectedNoiseLayer = useMemo(
    () =>
      new GeoJsonLayer({
        id: "selectedNoiseLayer",
        data: selectedNoiseContours,
        pickable: true,
        filled: true,
        stroked: false,
        lineWidthUnits: "pixels",
        opacity: opacity,
        visible: showNoise,
        getFillColor: (f) => [0, 122, 255, 64],
        transitions: {
          opacity: TRANSITION_TIME_MS,
        },
      }),
    [selectedNoiseContours, opacity, showNoise]
  );

  return { selectedNoiseLayer, comparingNoiseLayer };
};

export default useNoiseLayer;
