const handleTrackClick = (tracks, setSelectedTrackId, feature) => {
  const featureIndex = tracks.features.findIndex((f) => f === feature.object);
  if (featureIndex !== -1) {
    setSelectedTrackId(featureIndex);
  }
};

const editableTrackStyle = (opacity) => ({
  getFillColor: [0, 255, 0, 200],
  getEditHandlePointColor: [255, 255, 255],
  opacity,
  getLineColor: [0, 122, 255, 200],
  _subLayerProps: {
    guides: {
      getFillColor: [107, 194, 89],
      getLineColor: [0, 255, 0, 200],
    },
  },
  transitions: {
    opacity: 400,
  },
});

const editableTrackActions = ({
  setSelectedTrackId,
  selectedTrackId,
  tracks,
  updateTrack,
}) => ({
  onClick: (feature) => handleTrackClick(tracks, setSelectedTrackId, feature),
  onEdit: ({ updatedData }) => {
    updateTrack(selectedTrackId, updatedData);
  },
});

export { editableTrackStyle, editableTrackActions };
