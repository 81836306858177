import base_url from "../yankee-api";

export const updateTrack = (track) =>
  fetch(base_url + `/tracks`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(track),
  })
    .then((res) => res.json())
    .catch((error) => console.error(error));

export const getTrackComparation = (baseTrack, comparingTrack) => {
  return (
    fetch(base_url + `/compare/` + baseTrack + "/" + comparingTrack, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      // Parse the response
      .then((res) => res.json())
      .catch((error) => console.error(error))
  );
};

export const getTrackNoise = (track_id) =>
  fetch(base_url + `/tracks/` + track_id + "/noise", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch((error) => console.error(error));
