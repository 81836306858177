import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import { ViewMode } from "@nebula.gl/edit-modes";
import { useMemo, useContext, useRef, useEffect, useState } from "react";

import {
  editableTrackStyle,
  editableTrackActions,
} from "./editableTrackLayerHelper.js";
import { TracksContext } from "../../../context/TracksContext.js";
import { NoiseContext } from "../../../context/NoisesContext.js";

const NORMAL_OPACITY = 1;
const OPACITY_LOADING_1 = 0.2;
const OPACITY_LOADING_2 = 1;
const TRANSITION_TIME_MS = 600;

const useEditableTrackLayer = ({ mode, processTrack, processingTrack }) => {
  const {
    tracks,
    updateTrack,
    selectedTrackId,
    selectedTrack,
    setSelectedTrackId,
  } = useContext(TracksContext);
  const { invalidateNoiseContours } = useContext(NoiseContext);

  const EditableTrackLayer = useMemo(
    () =>
      class EditableTrackLayer extends EditableGeoJsonLayer {
        onStopDragging(event) {
          this.getActiveMode().handleStopDragging(
            event,
            this.getModeProps(this.props)
          );

          // Track was changed
          if (event.picks.length > 1) {
            processTrack();
            invalidateNoiseContours(selectedTrackId);
          }
        }
      },
    [processTrack, invalidateNoiseContours, selectedTrackId]
  );

  const intervalRef = useRef();
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    if (processingTrack) {
      setOpacity(OPACITY_LOADING_1);
      intervalRef.current = setInterval(() => {
        setOpacity((prev) => {
          if (prev !== OPACITY_LOADING_1) return OPACITY_LOADING_1;
          return OPACITY_LOADING_2;
        });
      }, TRANSITION_TIME_MS);
    } else {
      clearTimeout(intervalRef.current);
      setOpacity(NORMAL_OPACITY);
    }
  }, [processingTrack, setOpacity]);

  const editableTrackLayer = useMemo(
    () =>
      new EditableTrackLayer({
        id: "editable-track-layer",
        data: {
          type: "FeatureCollection",
          features: selectedTrack ? [selectedTrack] : [],
        },
        mode: selectedTrackId > -1 ? mode : ViewMode,
        selectedFeatureIndexes: selectedTrackId > -1 ? [0] : [],
        ...editableTrackStyle(opacity),
        ...editableTrackActions({
          setSelectedTrackId,
          selectedTrackId,
          tracks,
          updateTrack,
        }),
      }),
    [
      EditableTrackLayer,
      mode,
      selectedTrackId,
      setSelectedTrackId,
      selectedTrack,
      updateTrack,
      opacity,
      tracks,
    ]
  );
  return editableTrackLayer;
};

export default useEditableTrackLayer;
