import { useMemo } from "react";
import waypoints from "../../static-data/waypoints.js";
import { GeoJsonLayer, TextLayer } from "@deck.gl/layers";

const waypointLayer = new GeoJsonLayer({
  id: "waypointLayer",
  data: waypoints,
  filled: true,
  stroked: true,
  lineWidthUnits: "pixels",
  pointRadiusUnits: "pixels",
  getFillColor: [0, 0, 0, 0],
  getLineColor: [0, 0, 0, 255],
  getRadius: 10,
});

const useWaypointTextlayer = (visible) =>
  useMemo(
    () =>
      new TextLayer({
        id: "waypointTextLayer",
        data: waypoints["features"],
        getPosition: (d) => [
          d["geometry"]["coordinates"][0],
          d["geometry"]["coordinates"][1],
        ],
        visible,
        getText: (d) => d["properties"].name,
        getSize: 32,
        getAngle: 0,
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
      }),
    [visible]
  );

export { waypointLayer, useWaypointTextlayer };
