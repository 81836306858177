import React, { useContext } from "react";
import settingsIcon from "../../images/create-track/settings-icon.svg";
import infoIcon from "../../images/create-track/info-icon.svg";

import { Widget, WidgetContent, WidgetTitle } from "../../styles/Widgets";
import { ColumnSmallGap, InlineSmallGap } from "../../styles/Structure";
import { IconImg } from "../../styles/Icons";
import { CheckboxLabel } from "./styled";
import { TracksContext } from "../../context/TracksContext";
import { MapStateContext } from "../../context/MapStateContext";
import { NoiseContext } from "../../context/NoisesContext";
import Statistics from "./Statistics";

const ComparationWidget = ({
  communityStatistics,
  processingTrack,
  getTracksComparation,
}) => {
  const { tracks, selectedTrackId, comparingTrackId, setComparingTrackId } =
    useContext(TracksContext);

  const { updateTrackNoiseContours } = useContext(NoiseContext);
  const {
    showResidentialAreas,
    toogleResidentialAreas,
    showNoise,
    toogleNoise,
  } = useContext(MapStateContext);

  const handleChangeAlternativeTrack = (event) => {
    setComparingTrackId(event.target.value);
    if (event.target.value) {
      updateTrackNoiseContours(event.target.value);
      getTracksComparation(event.target.value);
    }
  };

  return (
    <Widget>
      <WidgetTitle>
        <div>Comparison</div>
        <InlineSmallGap>
          <IconImg
            src={settingsIcon}
            alt="icon used for opening up settings."
          />
          <IconImg
            src={infoIcon}
            alt="information icon used to show extra information."
          />
        </InlineSmallGap>
      </WidgetTitle>
      <WidgetContent>
        <ColumnSmallGap>
          <div>Compare with:</div>
          <select
            onChange={handleChangeAlternativeTrack}
            value={comparingTrackId || ""}
          >
            <optgroup label="tracks">
              <option value="">None</option>
              {tracks.features
                .filter(
                  (track) => selectedTrackId !== track.properties.track_id
                )
                .map((track) => (
                  <option
                    key={track.properties.track_id}
                    value={track.properties.track_id}
                  >
                    {track.properties.name}
                  </option>
                ))}
            </optgroup>
          </select>
        </ColumnSmallGap>

        <Statistics
          communityStatistics={communityStatistics}
          processingTrack={processingTrack}
        />
        <CheckboxLabel>
          <input
            type="checkbox"
            checked={showResidentialAreas}
            onChange={toogleResidentialAreas}
          />
          Show residental areas
        </CheckboxLabel>
        <CheckboxLabel>
          <input type="checkbox" checked={showNoise} onChange={toogleNoise} />
          Show noise contours
        </CheckboxLabel>
      </WidgetContent>
    </Widget>
  );
};

export default ComparationWidget;
