import styled, { css } from "styled-components";

const Button = styled.button`
  ${({ theme: { borderRadius, fonts } }) => {
    return css`
      font-size: 0.9rem;
      border-radius: ${borderRadius.small};
      border: none;
      font-weight: ${fonts.weight.bold};
      padding: 4px 10px;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      transition: 200ms;

      &:hover {
        opacity: 0.9;
      }
      &:disabled {
        opacity: 0.6;
      }
    `;
  }}
`;

const BlueButton = styled(Button)`
  ${({ theme: { colors } }) => {
    return css`
      color: white !important;
      background: ${colors.brandBlue} !important;
      & * {
        fill: white !important;
      }
    `;
  }}
`;

const TransparentButton = styled(Button)`
  ${({ theme: { colors } }) => {
    return css`
      color: ${colors.brandBlue} !important;
      background-color: transparent !important;

      &:hover {
        background-color: ${colors.brandBlueLighter} !important;
      }
    `;
  }}
`;

const BlueBorderedButton = styled(Button)`
  ${({ theme: { colors } }) => {
    return css`
      color: ${colors.brandBlue} !important;
      background: white !important;
      border: 1px solid ${colors.brandBlue} !important;
      & * {
        fill: ${colors.brandBlue} !important;
      }

      &:hover {
        background-color: ${colors.brandBlueLighter} !important;
      }
    `;
  }}
`;

const RedBorderedButton = styled(Button)`
  ${({ theme: { colors } }) => {
    return css`
      color: ${colors.brandRed} !important;
      background: white !important;
      border: 1px solid ${colors.brandRed} !important;
    `;
  }}
`;

const RedButton = styled(Button)`
  ${({ theme: { colors } }) => {
    return css`
      color: white !important;
      background: ${colors.brandRed} !important;
      border: 1px solid ${colors.brandRed} !important;
    `;
  }}
`;

export {
  Button,
  BlueButton,
  TransparentButton,
  BlueBorderedButton,
  RedBorderedButton,
  RedButton,
};
