import styled from "styled-components";
import { css } from "styled-components";

const SidebarTitle = styled.div`
  font-size: 16px;
  border-bottom: 2px solid lightgray;
  padding: 12px;

  display: flex;
  justify-content: space-between;
`;

const SidebarElement = styled.div`
  ${({ $selected, $width }) => {
    return css`
      font-size: 14px;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      transition: 200ms;
      cursor: pointer;
      border-bottom: 1px solid lightgray;

      ${$selected &&
      css`
        background-color: lightgray;
      `}
      &:hover {
        background-color: lightgray;
      }
    `;
  }}
`;

export { SidebarTitle, SidebarElement };
