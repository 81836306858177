import runways from "../../static-data/runways.json";
import { GeoJsonLayer } from "@deck.gl/layers";

const runwaysLayer = new GeoJsonLayer({
  id: "runwaysLayer",
  data: runways,
  lineWidthMinPixels: 2,
  getLineWidth: 20,
  getLineColor: [255, 0, 0, 255],
  getRadius: 100,
});

export default runwaysLayer;
