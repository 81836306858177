import styled, { css } from "styled-components";

const IconImg = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
  transition: 200ms;
  border-radius: 100%;
  padding: 4px;
  margin: 0 -2px;

  &:hover {
    background-color: lightgray;
  }
`;

const SvgWithColor = styled.svg`
  ${({ $color, $backgroundColor }) => {
    return css`
      cursor: pointer;
      fill: ${$color};
      padding: 4px;
      margin: 0 -2px;
      background-color: ${$backgroundColor};

      transition: 200ms;
      border-radius: 100%;
      &:hover {
        opacity: 0.6;
      }
    `;
  }}
`;

export { IconImg, SvgWithColor };
