import React, { useCallback, useContext, useState } from "react";
import ViewEdit from "./ViewEdit";
import runwaySorter from "../../tools/runwaySorter";
import copyIcon from "../../images/create-track/content-copy.svg";
import deleteIcon from "../../images/create-track/delete-icon.svg";
import downArrow from "../../images/create-track/down-arrow.png";
import { InlineGap } from "../../styles/Structure";
import runways from "../../static-data/runways.json";
import { Widget, WidgetContent, WidgetFooter } from "../../styles/Widgets";
import { IconImg } from "../../styles/Icons";
import {
  EditTrackTitle,
  ListHolder,
  ListItem,
  TrackNameInput,
  UnorderedList,
} from "./styled";
import { TracksContext } from "../../context/TracksContext";

function EditTrack({ setMode }) {
  const {
    tracks,
    setTracks,
    selectedTrack,
    selectedTrackId,
    setSelectedTrackId,
  } = useContext(TracksContext);

  const [openWidget, setOpenWidget] = useState(true);

  const toggleWidget = () => {
    setOpenWidget((state) => !state);
  };

  const duplicateFeature = (index) => {
    const feature = tracks.features[index];

    // copy the feature so the original would not get changed
    let copiedFeature = JSON.parse(JSON.stringify(feature));

    // give a new name to the copied feature.
    copiedFeature["properties"]["name"] =
      feature["properties"]["name"] + " copy";

    // give a new track id
    copiedFeature["properties"]["track_id"] = tracks.features.length;

    // add the copied feature to the rest of the existing features
    setTracks({
      ...tracks,
      features: [...tracks.features, copiedFeature],
    });
    // select to the new copied feature to make it active/selected
    setSelectedTrackId(tracks.features.length);
  };

  const changeRunway = (runway) => {
    // Get the runway feature
    let runway_feature_index = runways.features.findIndex(
      (f) => f.properties.runway === runway
    );
    let runway_feature = runways.features[runway_feature_index];

    // Get the new coordinates
    let runway_coordinates = runway_feature.geometry.coordinates;

    // Copy all the features
    let temp = {
      ...tracks,
      features: [...tracks.features],
    };

    // Replace the first two coordinates of the selected track
    temp.features[selectedTrackId].geometry.coordinates[0] =
      runway_coordinates[0];
    temp.features[selectedTrackId].geometry.coordinates[1] =
      runway_coordinates[1];

    // Replace the runway property
    temp.features[selectedTrackId].properties.runway = runway;

    // Update the features
    setTracks(temp);
  };

  const removeFeature = useCallback(
    (index) => {
      // make it a real pop up using hooks
      tracks.features.splice(index, 1);
      setSelectedTrackId(null);
      setTracks({
        ...tracks,
        features: [...tracks.features],
      });
    },
    [setTracks, tracks, setSelectedTrackId]
  );

  const handleTrackNameChange = (event) => {
    setTracks((features) => {
      let temp = {
        ...features,
        features: [...features.features],
      };

      temp.features[selectedTrackId].properties.name = event.target.value;

      return temp;
    });
  };

  return (
    <Widget>
      <EditTrackTitle>
        <TrackNameInput
          onChange={handleTrackNameChange}
          maxLength="25"
          value={selectedTrack.properties.name}
        />
        <ViewEdit setMode={setMode} />
      </EditTrackTitle>
      <WidgetContent>
        <InlineGap>
          <div>
            <select
              onChange={(event) => changeRunway(event.target.value)}
              defaultValue={selectedTrack.properties.runway}
            >
              {runways.features.sort(runwaySorter).map((item, index) => (
                <option key={index} value={item.properties.runway}>
                  Departure from Runway {item.properties.runway}
                </option>
              ))}
            </select>
          </div>
          <IconImg
            src={downArrow}
            alt="a downward faced arrow icon"
            id="down-arrow"
            onClick={toggleWidget}
          />
        </InlineGap>
        {openWidget ? (
          <ListHolder>
            <UnorderedList>
              {selectedTrack.geometry.coordinates.map((coordinates, index) => (
                <ListItem key={index}>
                  ({coordinates[0].toFixed(3)}, {coordinates[1].toFixed(3)})
                </ListItem>
              ))}
            </UnorderedList>
          </ListHolder>
        ) : null}
      </WidgetContent>
      <WidgetFooter>
        <IconImg
          src={copyIcon}
          alt="an icon used to copy the created track."
          className="track-heading-icons"
          onClick={() => {
            duplicateFeature(selectedTrackId);
          }}
        />
        <IconImg
          src={deleteIcon}
          alt="an icon used to delete the created track."
          className="track-heading-icons"
          onClick={() => {
            removeFeature(selectedTrackId);
          }}
        />
      </WidgetFooter>
    </Widget>
  );
}

export default EditTrack;
