import { ScatterplotLayer } from "@deck.gl/layers";
import { useContext, useMemo } from "react";
import { MapStateContext } from "../../context/MapStateContext";

const useResidentsLayer = ({ processingTrack, residents }) => {
  const { showResidentialAreas } = useContext(MapStateContext);
  const residentsLayer = useMemo(() => {
    return new ScatterplotLayer({
      id: "residentsLayer",
      data: residents,
      pickable: true,
      opacity: processingTrack ? 0.3 : 0.8,
      stroked: true,
      filled: true,
      radiusScale: 6,
      radiusMinPixels: 1,
      radiusMaxPixels: 100,
      lineWidthMinPixels: 1,
      getPosition: (d) => [d.lon, d.lat],
      getRadius: 25,
      getFillColor: processingTrack ? [180, 150, 150] : [255, 140, 0],
      getLineColor: processingTrack ? [50, 50, 50] : [0, 0, 0],
      visible: showResidentialAreas,
      transitions: {
        opacity: 400,
        getLineColor: 400,
        getFillColor: 400,
      },
    });
  }, [showResidentialAreas, residents, processingTrack]);

  return residentsLayer;
};

export default useResidentsLayer;
