import styled from "styled-components";
import { BlueButton } from "../../components/Buttons";
import { LoginContainer, LoginPage } from "./styled";
import { useAuth0 } from "@auth0/auth0-react";

const BlueButtonStyled = styled(BlueButton)`
  padding: 5px 20px;
  font-size: 20px;
`;

function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <LoginPage>
      <LoginContainer>
        <h1>Echo Platform</h1>
        <p>Welcome to Echo Platform. Here you can manage your airport data.</p>
        <BlueButtonStyled onClick={loginWithRedirect}>Log in</BlueButtonStyled>
      </LoginContainer>
    </LoginPage>
  );
}

export default Login;
